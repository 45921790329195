<template>
  <!-- 首页 -->
  <div>
    <!-- 导航栏 -->
    <Medse></Medse>
    <!-- 滚动到特定元素按钮 -->
    <div class="box-imgname">
      <img
        width="100%"
        height="100%"
        src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/hern1.png"
        alt=""
      />
    </div>

    <div class="pinpai">
      品牌故事
      <span></span>
    </div>
    <div class="text-name">
      “凯螺到家”是凯螺科技自主开发的建材新零售平台，自有码头、车队、船队和超过1万平方米的基础建材仓库，致力于基础建材新零售领域的创新。
      平台专注于基础建材（水泥、砂、石、砖、陶粒、瓷砖胶等）以及打拆清运服务，肩负“把好建材送到消费者手中”为使命，以客户为中心，团队合作，让天下没有难做的建材生意。
    </div>
    <div class="boc-ckcakal">
      <div class="left-img">
        <img
          width="100%"
          height="100%"
          src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/hern2.webp"
        />
      </div>
      <div class="right-img">
        <ul>
          <li>
            <img
              src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/pag-01.webp"
              alt=""
            />
          </li>
          <li>
            <img
              src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/pag-02.webp"
              alt=""
            />
          </li>
          <li>
            <img
              src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/pag-03.webp"
              alt=""
            />
          </li>
          <li>
            <img
              src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/pag-04.webp"
              alt=""
            />
          </li>
        </ul>
      </div>
    </div>
    <div class="cj-box">
      <div class="pinpai-2">
        当前成绩
        <span></span>
      </div>
      <!-- 成绩 -->
      <div class="stats-container">
        <div class="stat-item">
          <div class="suz-box1">
            <div>
              <span class="number">300</span>
              <span class="label">月流水交易额</span>
            </div>
            <div class="dw-box1">
              <span class="gg">/</span>
              <span class="unit">万元</span>
              <span class="gg">/</span>
            </div>
          </div>
        </div>
        <div class="stat-item">
          <div class="suz-box1">
            <div>
              <span class="number">40</span>
              <span class="label">市场关注度</span>
            </div>
            <div class="dw-box1">
              <span class="gg">/</span>
              <span class="unit">万/人次</span>
              <span class="gg">/</span>
            </div>
          </div>
        </div>
        <div class="stat-item">
          <div class="suz-box1">
            <div>
              <span class="number">4</span>
            </div>
            <div class="dw-box1">
              <span class="gg">/</span>
              <span class="unit">大城市</span>
              <span class="gg">/</span>
            </div>
            <div class="cities">
              <p>广州</p>
              <p>上海</p>
              <p>徐州</p>
              <p>济南</p>
            </div>
          </div>
          <!-- <span class="number">4</span>
          <span class="unit">大城市</span>
          <span class="label">当前服务范围</span>
          <span class="cities">广州 上海 徐州 济南</span> -->
          <span class="label-aa">当前服务范围</span>
        </div>
      </div>
      <div class="box-fuwu">
        <!-- <img src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/fuwu1.webp" /> -->
        <img src="../../assets/imgs/bgt1x2.png" alt="" />
      </div>
    </div>
    <BottomTonCopy class="listBox"></BottomTonCopy>
    <!-- 点击返回顶部 -->
    <Topup></Topup>
  </div>
</template>

<script>
import BottomTonCopy from "@/components/BottomTonCopy.vue";
import Topup from "@/components/Topup.vue";
import Medse from "@/components/Medse.vue";
export default {
  components: {
    BottomTonCopy,
    Topup,
    Medse,
  },
  name: "loginName",
};
</script>

<style scoped lang="less">
.listBox {
  width: 1920px;
  background-color: #f0f0f0;
  border-top: 4px solid #f67100;
  position: relative;
}
.box-imgname {
  width: 1920px;
  height: 988px;
  margin: 0 auto;
  background-size: contain;
}
.pinpai {
  width: 1920px;
  height: 154px;
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 60px;
  color: #333333;
  line-height: 110px;
  text-align: center;
  margin: 70px auto 19px;
  span {
    width: 110px;
    height: 3px;
    background: #f67100;
    display: block;
    text-align: center;
    // text-align: center;
    margin: 0 auto;
  }
}

.text-name {
  width: 1600px;
  height: 102px;
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 22px;
  color: #333333;
  line-height: 40px;
  // text-align: center;
  margin: 0 auto;
  text-indent: 1em;
}
.boc-ckcakal {
  display: flex;
  margin: 61px 159px;
  width: 1604px;
  height: 462px;
  // background-color: palegoldenrod;
  .left-img {
    width: 726px;
    height: 462px;
    margin-right: 30px;
  }
  .right-img {
    flex: 1;
    ul {
      display: flex;
      width: 100%;
      height: 462px;
      flex-wrap: wrap;
      align-content: space-between;
      justify-content: space-between;
      li {
        display: block;
        width: 411px;
        height: 216px;
        // background-color: #f67100;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.cj-box {
  position: relative;

  .pinpai-2 {
    position: absolute;
    width: 1920px;
    // height: 154px;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 60px;
    color: #333333;
    line-height: 110px;
    text-align: center;
    margin: 42.5px auto 19px;
    span {
      width: 110px;
      height: 3px;
      background: #f67100;
      display: block;
      text-align: center;
      // text-align: center;
      margin: 0 auto;
    }
  }
}
.box-fuwu {
  width: 1920px;
  height: 463px;
  img {
    width: 100%;
    height: 100%;
  }
}
/* 将 ol, ul, li 列表的列表标记设置为 none */
ol,
ul,
li {
  list-style: none;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: inherit; /* 继承父元素的文本颜色 */
  text-decoration: none; /* 移除下划线 */
}

body {
  margin: 0;
  padding: 0;
  border: none;
}
// 当前成绩
body {
  font-family: Arial, sans-serif;
}

.stats-container {
  position: absolute;
  top: 170px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  // background-color: #f8f8f8;
}

.stat-item {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  text-align: center;
  color: #f67100;
}

.number {
  display: block;
  font-size: 100px;
  font-weight: bold;
}
.suz-box1 {
  display: flex;
  width: 250px;
  .dw-box1 {
    margin-left: 16px;
    margin-top: 20px;
    font-family: PingFang SC;
    // font-weight: 500;
    // font-size: 10px;
    color: #666666;
    // line-height: 15px;
  }
}

.unit {
  display: block;
  font-size: 24px;
  margin-bottom: 10px;
}

.label {
  display: block;
  font-size: 16px;
  color: #666666; /* 灰色的文字颜色 */
}
.label-aa{
  position: absolute;
  font-size: 16px;
  color: #666666;
  margin-top: 138px;
  margin-left: -126px;
}
.gg{
  margin: 0;
  padding: 0;
  font-size: 25px;
  color: #666666;
}

.cities {
  display: flex;
  flex-wrap: wrap;
  display: block;
  font-size: 14px;
  margin-top: 25px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;

  p {
    margin: 5px;
  }
}
</style>
