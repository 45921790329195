<template>
  <div id="app">
    <!-- 点击返回顶部 -->
    <Topup></Topup>
    <!-- <BiaoDan></BiaoDan> -->
    <router-view />
  </div>
</template>
<script>
import Topup from '@/components/Topup.vue'
// import BiaoDan from '@/components/BiaoDan.vue'
export default {
  components: {
    Topup
    // BiaoDan
  }
}
</script>
<style lang="less">
div {
  width: 1920px;
}
/* 将 ol, ul, li 列表的列表标记设置为 none */
ol,
ul,
li {
  list-style: none;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: inherit; /* 继承父元素的文本颜色 */
  text-decoration: none; /* 移除下划线 */
}

body {
  margin: 0;
  padding: 0;
  border: none;
}
 </style>
