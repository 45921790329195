import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '@/views/login'
import place from '@/views/place'
import service from '@/views/service'
import industry from '@/views/industry'
import invite from '@/views/invite'
import women from '@/views/women'
import industryDetails from '@/views/industryDetails'
import productDetails from '@/views/productDetails'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      path: '/',
      component: login
    },
    {
      path: '/login',
      component: login
    },
    {
      path: '/place',
      component: place
    },
    {
      path: '/service',
      component: service
    },
    {
      path: '/industry',
      component: industry
    },
    {
      path: '/invite',
      component: invite
    },
    {
      path: '/women',
      component: women
    },
    {
      path: '/industryDetails',
      component: industryDetails,
      props: route => ({ id: route.query.id }) // 将路由参数 id 作为 props 传递
    },
    {
      path: '/productDetails',
      component: productDetails,
      props: route => ({ id: route.query.id }) // 将路由参数 id 作为 props 传递
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
})

export default router
