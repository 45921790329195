<template>
  <!-- 产品&服务 -->
  <div>
    <div class="bottom-DisplayFrame">
      <div class="pinpai">
        <h2 class="titleh2">
          {{ currentCategory ? currentCategory.name : "" }}
        </h2>
        <span></span>
      </div>
      <div class="btn-Details">
        <ul class="btn-Details-ul">
          <router-link
            v-for="product in visibleProducts"
            :key="product.id"
            :to="
              product.id !== 'placeholder'
                ? { path: '/productDetails', query: { id: product.id } }
                : '#'
            "
            tag="li"
            class="akcaade"
          >
            <div class="btn-Details-Biagram">
              <img
                :src="
                  product.id !== 'placeholder'
                    ? getImageUrl(product.ico)
                    : defaultImageUrl
                "
                alt=""
                class="liimg"
              />
            </div>
            <div class="btn-Details-Explain">
              <p>{{ product.title }}</p>
            </div>
          </router-link>
        </ul>
        <div class="gjiantou-left" @click="prevSlide">
          <img src="../assets/toubufanhuijiantou 01@2x.png" />
        </div>
        <div class="gjiantou-right" @click="nextSlide">
          <img src="../assets/toubufanhuijiantou 02@2x.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProductList } from "@/api";
import { imageService } from "@/utils/request";

export default {
  props: ["categories", "currentCategoryIndex"],
  data() {
    return {
      products: [],
      currentIndex: 0,
      intervalId: null,
      defaultImageUrl:
        "https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/%E6%97%A0%E5%9B%BE1.png",
    };
  },
  computed: {
    currentCategory() {
      return this.categories && this.categories.length > 0
        ? this.categories[this.currentCategoryIndex]
        : null;
    },
    visibleProducts() {
      if (!this.products.length) return [];
      let validProducts = this.products.map((product) => {
        if (product.id === null || product.title === null) {
          return {
            id: "placeholder",
            title: "商品正在整理中...",
            ico: "placeholder-image-url", // 占位符图片的URL
          };
        }
        return product;
      });

      if (validProducts.length <= 5) {
        return validProducts;
      }

      let start = this.currentIndex;
      let end = start + 5;
      if (end > validProducts.length) {
        return validProducts
          .slice(start)
          .concat(validProducts.slice(0, end - validProducts.length));
      } else {
        return validProducts.slice(start, end);
      }
    },
  },
  async mounted() {
    if (this.categories && this.categories.length > 0) {
      await this.initCarousel();
    } else {
      this.$watch(
        "categories",
        async (newCategories, oldCategories) => {
          if (
            newCategories &&
            newCategories.length > 0 &&
            newCategories !== oldCategories
          ) {
            await this.initCarousel();
          }
        },
        { immediate: true }
      );
    }
  },
  beforeDestroy() {
    this.stopAutoSlide();
  },
  methods: {
    isCompleteUrl(url) {
      return (
        typeof url === "string" &&
        (url.startsWith("http://") || url.startsWith("https://"))
      );
    },
    getImageUrl(path) {
      if (!path) {
        return this.defaultImageUrl;
      }
      return this.isCompleteUrl(path)
        ? path
        : `${imageService.defaults.baseURL}${path}`;
    },
    nextSlide() {
      if (!this.products.length) return;
      if (this.currentIndex + 5 < this.products.length) {
        this.currentIndex++;
      } else {
        const nextCategoryIndex =
          (this.currentCategoryIndex + 1) % this.categories.length;
        this.updateCategory(nextCategoryIndex);
      }
    },
    prevSlide() {
      if (!this.products.length) return;
      if (this.currentIndex > 0) {
        this.currentIndex--;
      } else {
        const prevCategoryIndex =
          (this.currentCategoryIndex - 1 + this.categories.length) %
          this.categories.length;
        this.updateCategory(prevCategoryIndex);
      }
    },
    startAutoSlide() {
      this.stopAutoSlide(); // 先清除已有的定时器
      this.intervalId = setInterval(this.nextSlide, 5000);
    },
    stopAutoSlide() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    async fetchCategoryProducts(index) {
      const category = this.categories && this.categories[index];
      if (!category) return;

      const cacheKey = `category_${category.scode}`;
      const cachedData = localStorage.getItem(cacheKey);

      if (cachedData) {
        // console.log(`使用缓存数据: ${cacheKey}`);
        this.products = JSON.parse(cachedData);
        this.currentIndex = 0;
      } else {
        try {
          const response = await getProductList();
          const categoryData = response.data.find(
            (cat) => cat.scode === category.scode
          );
          if (categoryData && categoryData.product) {
            this.products = categoryData.product;
            localStorage.setItem(
              cacheKey,
              JSON.stringify(categoryData.product)
            );
            this.currentIndex = 0;
          }
        } catch (error) {
          console.error("获取分类产品失败:", error);
        }
      }
    },
    async initCarousel() {
      this.currentCategoryIndex = 0; // 默认选择第一个分类
      await this.fetchCategoryProducts(this.currentCategoryIndex);
      this.startAutoSlide();
    },
    async updateCategory(index) {
      this.stopAutoSlide(); // 停止自动轮播
      this.$emit("categoryChange", index); // 立即触发事件
      await this.fetchCategoryProducts(index); // 获取新分类的产品列表
      this.currentIndex = 0; // 重置currentIndex为0
      this.startAutoSlide(); // 重新开始自动轮播
    },
  },
  watch: {
    async currentCategoryIndex(newIndex, oldIndex) {
      if (
        newIndex !== oldIndex &&
        this.categories &&
        this.categories.length > 0
      ) {
        this.stopAutoSlide(); // 停止自动轮播
        await this.fetchCategoryProducts(newIndex); // 获取新分类的产品列表
        this.currentIndex = 0; // 重置currentIndex为0
        this.startAutoSlide(); // 重新开始自动轮播
      }
    },
  },
};
</script>



<style scoped lang="less">
.titleh2 {
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 60px;
  color: #333333;
}

.bottom-DisplayFrame {
  width: 1920px;
  height: 590px;
  background-color: #f0f0f0;
  margin: 0 auto;

  .pinpai {
    width: 1920px;
    height: 154px;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 60px;
    color: #333333;
    line-height: 110px;
    text-align: center;

    span {
      width: 110px;
      height: 3px;
      background: #f67100;
      display: block;
      margin: 0 auto;
    }
  }
}

.btn-Details {
  position: relative;
  margin: 0 auto;
  width: 1524px;
  height: 346px;

  ul {
    display: flex;
    justify-content: space-between;
    padding-inline-start: 0px;

    li {
      .btn-Details-Biagram {
        width: 268px;
        height: 266px;
        display: flex;

        a {
          img {
            width: 268px;
            height: 266px;
          }
        }
      }

      .btn-Details-Explain {
        width: 268px;
        height: 80px;
        background-color: #ffffff;
        border-radius: 0px 0px 7px 7px;
        line-height: 80px;
        text-align: center;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #777777;

        p {
          margin: 0;
        }
      }
    }
  }

  .akcaade {
    cursor: pointer;
    // max-width: 86px;
    // max-height: 226px;
  }

  .liimg {
    width: 268px;
    height: 266px;
  }

  .gjiantou-left {
    cursor: pointer;
    position: absolute;
    width: 38px;
    // height: 38px;
    z-index: 1;
    top: 161px;
    left: -70px;
    line-height: 2px;

    img {
      width: 38px;
      height: 38px;
    }
  }

  .gjiantou-right {
    cursor: pointer;
    position: absolute;
    width: 38px;
    // height: 38px;
    z-index: 1;
    top: 161px;
    line-height: 38px;
    right: -70px;

    img {
      width: 38px;
      height: 38px;
    }
  }
}

/* 将 ol, ul, li 列表的列表标记设置为 none */
ol,
ul {
  list-style: none;
}

/* 去除链接的下划线，并设置为默认的颜色 */
a {
  text-decoration: none;
  color: inherit;
}
</style>
